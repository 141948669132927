export const navItemsList = [
    { linkLabel: 'Professional Services', url: '/professional-services' },
    {
        isDisplayed: false,
        dropdownLinkLabel: 'Solutions',
        dropdownLink: [
            {
                linkLabel: 'Products', // Este es el ítem que tendrá un submenú
                hasSubDropdown: true,
                subDropdownLabel: 'Products',
                subDropdownLink: [
                    { linkLabel: 'Track & Trace', url: '/supply-chain-optimization-software' },
                    { linkLabel: 'Artificial Intelligence', url: '/industries/artificial-intelligence-ai' },
                ],
            },
            {
                linkLabel: 'Industries', // Este es el ítem que tendrá un submenú
                hasSubDropdown: true,
                subDropdownLabel: 'Industries',
                subDropdownLink: [
                    { linkLabel: 'Financial Services', url: '/industries/financialservices' },
                    { linkLabel: 'Retail', url: '/industries/retail' },
                    { linkLabel: 'Government', url: '/industries/government' },
                    { linkLabel: 'Entertainment & Gaming', url: '/industries/entertainment-and-gaming' },
                    { linkLabel: 'Manufacturing & Supply Chain', url: '/industries/supply-chain' },
                ],
            },
        ],
    },
    { linkLabel: 'Customer Stories', url: '/customer-stories' },
    { linkLabel: 'Blog', url: '/blog' },
    {
        isDisplayed: false,
        dropdownLinkLabel: 'Developers',
        dropdownLink: [
            { linkLabel: 'Developer Portal', url: 'https://developer.casper.network/', isExternal: true },
            { linkLabel: 'Casper Association', url: 'https://casper.network/en-us/casper', isExternal: true },
            { linkLabel: 'White Paper', url: 'https://arxiv.org/pdf/2101.02159.pdf', isExternal: true },
        ],
    },
    {
        isDisplayed: false,
        dropdownLinkLabel: 'About',
        dropdownLink: [
            { linkLabel: 'Company', url: '/company' },
            { linkLabel: 'Team', url: '/team' },
            { linkLabel: 'Careers', url: '/careers' },
            { linkLabel: 'In the News', url: '/in-the-news' },
        ],
    },
]
