import type { AppProps } from 'next/app'
import CompanyLogoImg from '@assets/icons/logo-white.svg'
import { useRouter } from 'next/router'
import { footerItemsList, navItemsList } from 'src/common'
import '@styles/main.scss'
import { Consent, Providers } from '@modules/layout'

import Footer from '@atk/components/layout/Footer/Footer'
import NavigationDefault from '@atk/components/layout/Navigation/NavigationDefault/NavigationDefault'

export type ResponseProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    res: any
}

export default function App({ Component, pageProps }: AppProps) {
    const router = useRouter()
    return (
        <Providers pageProps={pageProps}>
            <Consent>
                {router.pathname !== '/blockchain-paris' && router.pathname !== '/consensus' && (
                    <NavigationDefault logoSrc={CompanyLogoImg.src} items={navItemsList} />
                )}
                <Component {...pageProps} />
                <Footer logoSrc={CompanyLogoImg.src} items={footerItemsList} />
            </Consent>
        </Providers>
    )
}
