import Script from 'next/script'

import { CookieSettingsForm } from '../Consent/Consent.types'

export default function PageScripts({ consentSettings }: { consentSettings: CookieSettingsForm | undefined }) {
    return (
        <>
            {consentSettings?.performanceCookies && (
                <>
                    <Script
                        defer
                        src="https://www.googletagmanager.com/gtag/js?id=G-JT6SXPEC9F"
                        strategy="afterInteractive"
                    />
                    <Script
                        defer
                        src="https://www.googletagmanager.com/gtag/js?id=UA-133833104-1"
                        strategy="afterInteractive"
                    />
                    <Script
                        defer
                        src="https://www.googletagmanager.com/gtag/js?id=AW-11412130437"
                        strategy="afterInteractive"
                    />
                    <Script defer id="google-analytics" strategy="afterInteractive">
                        {`
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    const trackingIds = ['G-JT6SXPEC9F', 'UA-133833104-1', 'AW-11412130437'];
                                
                                    trackingIds.forEach(trackingId => {
                                        gtag('config', trackingId);
                                    });
                                `}
                    </Script>
                    {/* Google Tag Manager */}
                    <Script defer id="gtm">
                        {`
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-PVBS9KR');
                        `}
                    </Script>
                </>
            )}
            {/* End Google Tag Manager */}
            <Script defer src="https://js.hsforms.net/forms/v2.js" />
            {consentSettings?.marketingCookies && (
                <Script
                    id="linkedin"
                    defer
                    dangerouslySetInnerHTML={{
                        __html: `
                            window._linkedin_partner_id = "4856620";
                            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                            window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
                            
                            if (!window.lintrk) {
                            window.lintrk = function (a, b) {
                                window.lintrk.q.push([a, b]);
                            };
                            window.lintrk.q = [];
                            }
                            
                            (function (l) {
                            var s = document.getElementsByTagName("script")[0];
                            var b = document.createElement("script");
                            b.type = "text/javascript";
                            b.async = true;
                            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                            s.parentNode.insertBefore(b, s);
                            })(window.lintrk);
                        `,
                    }}
                />
            )}
            {consentSettings?.performanceCookies && (
                <Script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src="//js.hs-scripts.com/6073987.js"
                ></Script>
            )}
        </>
    )
}
