import { useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import Link from 'next/link'
import Cookies from 'js-cookie'
import { cookieConsentData } from 'src/common'
import dynamic from 'next/dynamic'

import styles from './Consent.module.scss'
import { CookieSettingsForm } from './Consent.types'
import { PageScripts } from '../PageScripts'
const ConsentSettings = dynamic(() => import('../Consent/ConsentSettings'))

export default function Consent({ children }: { children: React.ReactNode }) {
    const consentInitialValue = Cookies.get('consent') || ''
    const [consent, setConsent] = useState<string>(consentInitialValue)
    const [showCookieSettings, setShowCookieSettings] = useState(false)
    const [consentSettings, setConsentSettings] = useState<CookieSettingsForm | undefined>(undefined)

    const saveCookie = (consentValue: CookieSettingsForm) => {
        setConsentSettings(consentValue)
        const jsonValue = JSON.stringify(consentValue)
        setConsent(jsonValue)
        Cookies.set('consent', jsonValue, { expires: 365 })
    }

    const handleCookiesSettingsOnClick = (consentValue: CookieSettingsForm) => {
        saveCookie(consentValue)
        setShowCookieSettings(false)
    }

    useEffect(() => {
        if (consent) {
            setConsentSettings(JSON.parse(consent))
        } else {
            const cookies = Cookies.get()
            for (const cookieName in cookies) {
                if (cookies.hasOwnProperty(cookieName)) {
                    Cookies.remove(cookieName)
                }
            }
        }
    }, [consent])
    return (
        <>
            {children}
            {!consent && (
                <CookieConsent
                    containerClasses={styles.cookieConsent}
                    contentClasses={styles.cookieText}
                    buttonWrapperClasses={styles.cookieActions}
                    location="bottom"
                    buttonText="Accept all"
                    cookieName="consent"
                    cookieValue={consent}
                    declineCookieValue={consent}
                    enableDeclineButton
                    declineButtonText="Reject all"
                    buttonClasses={`btn btn-secondary ${styles.confirmButton}`}
                    declineButtonClasses={`btn btn-outline ${styles.declineButton}`}
                    expires={365}
                    onDecline={() => {
                        const consentValue: CookieSettingsForm = {
                            essentialCookies: false,
                            marketingCookies: false,
                            performanceCookies: false,
                        }
                        saveCookie(consentValue)
                    }}
                    onAccept={() => {
                        const consentValue: CookieSettingsForm = {
                            essentialCookies: true,
                            marketingCookies: true,
                            performanceCookies: true,
                        }
                        setConsentSettings(consentValue)
                        saveCookie(consentValue)
                    }}
                >
                    {cookieConsentData.policyText}
                    <Link href="/legal/privacy">Privacy Policy</Link>
                    <div className="mt-3">
                        <span className="settings text-green" onClick={() => setShowCookieSettings(true)}>
                            Cookie settings
                        </span>
                    </div>
                </CookieConsent>
            )}
            {showCookieSettings && <ConsentSettings onSave={handleCookiesSettingsOnClick} />}
            <PageScripts consentSettings={consentSettings} />
        </>
    )
}
