/* eslint-disable @typescript-eslint/no-explicit-any */
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ParallaxProvider } from 'react-scroll-parallax'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { ReactQueryDevtools } from 'react-query/devtools'
import { myFonts } from '@configs/local-fonts'

import AppBootstrapProvider from '@atk/components/providers/AppBootstrapProvider/AppBootstrapProvider'

export default function Providers({ pageProps, children }: { pageProps: any; children: React.ReactNode }) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: 300000, //Data fetch for 5 min.
                    },
                },
            })
    )
    const router = useRouter()
    useEffect(() => {
        if (router.pathname === '/_error') {
            router.push('/')
        }
    }, [router])
    return (
        <AppBootstrapProvider>
            <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                    <ParallaxProvider>
                        <div className={myFonts.className}>{children}</div>
                    </ParallaxProvider>
                </Hydrate>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </AppBootstrapProvider>
    )
}
