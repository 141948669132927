const cookieConsentData = {
    policyText: `We use necessary cookies to make our site work and, if you consent, analytics and/or third party cookies to personalize website content and analyze traffic. The optional cookies are from Google, HubSpot and AdRoll. By clicking on "Accept all" you agree to the use of all cookies. If you do not wish to accept all kinds of cookies click on "Cookie settings". If you only want to allow technically necessary cookies, click on "Reject all". For more details please see our `,
    settingText: `We use necessary cookies to make our site work. We would also like to set optional analytics
cookies and/or third party cookies. We will not set optional cookies and/or third party cookies unless
you enable them. Using this tool will set a cookie on your device to remember your preferences.
For detailed information about the cookies we use, see our `,
    essentialCookies: `These cookies are necessary to maintain our services and cannot be switched off. They are usually only set in response to actions made by you. You can set your browser to block or alert you about these cookies, but that can make some parts of the site not work. These cookies do not store any personally identifiable information.`,
    performanceCookies: `These cookies allow us to count visits and traffic so we can collect insights like which pages are the most popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore, anonymous. If you do not allow these cookies, we will not be able to provide you with a tailored experience.`,
    marketingCookies: `When you accept marketing cookies, you give us your consent to place cookies on your device to provide you with relevant content that fits your interests. These cookies may be set through our site by our advertising partners or by us to build a profile of your interests and show you relevant content. To deliver content that fits your interests on our site, we will use your interactions together with the personal information you have provided to us. To present you with relevant content on third-party sites, we will share this information and a customer identifier such as an encrypted email address or device ID with third parties, such as advertising platforms and social networks. To make the content as interesting as possible, we may link this data across the different devices you use. If you choose not to accept marketing cookies, we will not place such cookies on your device and you may experience less relevant content from us.`,
}

export { cookieConsentData }
