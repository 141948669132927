import { ReactSVG } from 'react-svg'

export type IconProps = {
    source: string
    customClass?: string
}

export default function Icon({ source, customClass }: IconProps) {
    return <ReactSVG className={customClass} src={source} />
}
