export const footerItemsList = [
    {
        content:
            ' Casper Labs lowers the barrier to entry for blockchain adoption by providing professional services and support for businesses building on Casper.',
        colNumber: 4,
        colMDNumber: 12,
    },
    {
        categoryTitle: 'Product Information',
        categoryItems: [
            {
                linkLabel: 'Protocol Information',
                linkUrl: 'https://arxiv.org/pdf/2101.02159.pdf',
                isExternal: true,
            },
            { linkLabel: 'Technical Specification', linkUrl: 'https://docs.casperlabs.io/', isExternal: true },
            {
                linkLabel: 'Releases',
                linkUrl: 'https://github.com/casper-network/casper-node/releases',
                isExternal: true,
            },
        ],
        colNumber: 3,
        colMDNumber: 4,
    },
    {
        categoryTitle: 'Developer Resources',
        categoryItems: [
            { linkLabel: 'Developer Portal', linkUrl: 'https://developer.casper.network/', isExternal: true },
            {
                linkLabel: 'GitHub (run by Casper Association)',
                linkUrl: 'https://github.com/casper-network',
                isExternal: true,
            },
        ],
        colNumber: 3,
        colMDNumber: 4,
    },
    {
        categoryTitle: 'About',
        categoryItems: [
            { linkLabel: 'Contact Us', linkUrl: '/contact' },
            { linkLabel: 'Company', linkUrl: '/company' },
            { linkLabel: 'Team', linkUrl: '/team' },
            { linkLabel: 'Careers', linkUrl: '/careers' },
        ],
        colNumber: 2,
        colMDNumber: 4,
    },
]
