import telanganaImg from '@assets/img/casperindia.jpeg'

import { getCMSImage } from '../functions/format'
import { Associations } from '../types/cms/associations'
import { Blog } from '../types/cms/blog'
import { Category, CategoryGroup } from '../types/cms/categories'
import { CMSList } from '../types/cms/cms-list'
import { Newsletter } from '../types/cms/newsletter'
import { Team } from '../types/cms/team'
import {
    GetBlogDetailParams,
    GetCaseStudyParams,
    GetCategoriesParams,
    GetDefaultParams,
    GetLegalDocsParams,
    GetLogosParams,
    GetPostsParams,
    GetTeamParams,
    GetTeamResult,
} from './cms-api.types'
import { CaseStudy } from '../types/cms/case-study'
import { Banner } from '../types/cms/banner'
import { News } from '../types/cms/news'
const baseUrl = process.env.NEXT_PUBLIC_CMS_URL
const site = process.env.NEXT_PUBLIC_SITE

const requestInitGet = {
    method: 'GET',
    headers: {
        site: site ?? '',
        'Content-Type': 'application/json',
    },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getQueryString = (url: string, params: any) => {
    const args = new URLSearchParams(params).toString()
    return url.concat(`?${args}`)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFromCms = async (url: string, args: any) => {
    const uri = getQueryString(`${baseUrl}${url}`, args)
    const res = await fetch(uri, requestInitGet)

    return await res.json()
}

const getFromCmsWithQueryString = async (url: string) => {
    const uri = `${baseUrl}${url}`
    const res = await fetch(uri, requestInitGet)

    return await res.json()
}

const setCategoryName = (posts: Blog[], categories: Category[]) => {
    return posts.map((post: Blog) => ({
        ...post,
        categoryName: categories.find((x) => x._id === post.categories.main)?.name || null,
    }))
}

const setPostWebpImages = (posts: Blog[]) => {
    return posts.map((p: Blog) => ({
        webpHeaderUrl: getCMSImage(p.headerUrl ?? p.thumbnailUrl),
        webpThumbnailUrl: getCMSImage(p.thumbnailUrl ?? p.headerUrl),
        ...p,
    }))
}

const getPostsSitemap = (args: GetPostsParams) => {
    return getFromCms(`papi/blog`, args)
}

const getCategoryGroups = async () => {
    const categoryGroupsAll: CMSList<CategoryGroup> = await getFromCms(`papi/category-groups`, {})
    return categoryGroupsAll.data.filter((catGroup) => catGroup.site === site)
}

const getPosts = async (args: GetPostsParams, categories: Category[]) => {
    const { slug, ...params } = args
    const relativeUrl = slug ? `papi/blog/category/${slug}/paged` : `papi/blog`
    const postsData = await getFromCms(relativeUrl, params)
    const posts = postsData as CMSList<Blog>
    posts.data = setCategoryName(posts.data, categories)
    posts.data = setPostWebpImages(posts.data)

    //ACCW-603: The client wants to modify a specific blog by force.
    //********************************** This should be eliminated when the customer does not require it ************************************* */
    const telanganaIndex = posts.data.findIndex((x) => x.meta.slug === 'blockchain-hub-davos-day-2-recap')
    if (telanganaIndex >= 0) {
        posts.data[telanganaIndex].externalLink =
            'https://www.businesswire.com/news/home/20220524005984/en/CasperLabs-to-Provide-Open-Permissioned-Network-for-Implementation-of-Government-of-Telangana-Blockchain-Initiatives'
        posts.data[telanganaIndex].webpThumbnailUrl = telanganaImg.src
        posts.data[telanganaIndex].title =
            'Casper Labs to Provide Open, Permissioned Network for Implementation of Government of Telangana Blockchain Initiatives'
        posts.data[telanganaIndex].legendDate = 'Originally announced on '
    }
    //**************************************************************************************************************************************** */
    return posts
}

const getBlog = async (args: GetPostsParams) => {
    const { slug, ...params } = args
    const relativeUrl = slug ? `papi/blog/category/${slug}/paged` : `papi/blog`
    return await getFromCms(relativeUrl, params)
}

const getCategoriesBySlug = async (args: GetCategoriesParams): Promise<Category[]> => {
    const { parent, ...restArgs } = args
    const { data } = await getFromCms(`api/categories`, restArgs)
    const parentCat = data.find((x: Category) => x.name === parent)
    return data.filter((x: Category) => x.parentId === parentCat._id)
}

const getBanner = async (args: GetDefaultParams) => {
    return await getFromCms(`papi/banner`, args)
}

const getBannerPage = async (args: GetDefaultParams & GetCaseStudyParams) => {
    const { slug, ...bannerArgs } = args
    const [bannerData, categoryData] = await Promise.all([
        getBanner(bannerArgs),
        getFromCmsWithQueryString(`api/categories/${slug}/slug`),
    ])
    const bannerList = bannerData as CMSList<Banner>
    const categories = categoryData as Category[]
    if (categories.length === 0) return bannerList.data
    const category = categories[0]

    const bannerFiltered = bannerList.data.filter(
        (x) => x.categories.main === category._id || (x.categories.all && x.categories.all.includes(category._id))
    )

    return bannerFiltered
}

const getBlogDetail = async (args: GetBlogDetailParams) => {
    return await getFromCms(`papi/blog`, args)
}

const getPostPage = async (postArgs: GetBlogDetailParams, catArgs: GetCategoriesParams) => {
    const [postData, categoriesData] = await Promise.all([getBlogDetail(postArgs), getCategoriesBySlug(catArgs)])
    const post = postData as Blog
    const categories = categoriesData as Category[]
    if (post.categories.main) post.categoryName = categories.find((x) => x._id === post.categories.main)?.name || null

    post.associationsData = await getAssociations(post.associations)
    post.associationsData.blog = setCategoryName(post.associationsData.blog, categories)
    post.associationsData.blog = setPostWebpImages(post.associationsData.blog)
    return post
}

const getCaseStudyPage = async ({ slug }: GetCaseStudyParams) => {
    const [caseStudyData, categoryData] = await Promise.all([
        getCaseStudies({ sort: 'position', limit: 0 }),
        getFromCmsWithQueryString(`api/categories/${slug}/slug`),
    ])
    const caseStudyList = caseStudyData as CMSList<CaseStudy>
    const categories = categoryData as Category[]
    if (categories.length === 0) return caseStudyList.data
    const category = categories[0]

    const caseStudyFiltered = caseStudyList.data.filter(
        (x) => x.categories.main === category._id || x.categories.all.includes(category._id)
    )

    return caseStudyFiltered
}

const getCaseStudies = async (args: GetDefaultParams) => {
    return await getFromCms(`papi/caseStudy`, args)
}

const getCareers = async () => {
    const args = { sort: 'timeCreated', limit: 0 }
    return await getFromCms(`papi/jobs`, args)
}

const getLogos = async (args: GetLogosParams) => {
    return await getFromCms(`papi/logo`, args)
}

const getLegalDocs = async (args: GetLegalDocsParams) => {
    return await getFromCms(`papi/legaldoc`, args)
}

const getTeams = async (args: GetTeamParams) => {
    return await getFromCms(`papi/team`, args)
}

const getTeamsSorted = (team: Team[], category: Category) => {
    if (category.meta?.slug.includes('founders') || category.meta?.slug.includes('executive')) return team
    else
        return team.sort((a: Team, b: Team) => {
            const _a = a.name.split(' ').pop() ?? ''
            const _b = b.name.split(' ').pop() ?? ''
            return _a.toLowerCase() >= _b.toLowerCase() ? 1 : -1
        })
}

async function getTeamPage(): Promise<GetTeamResult[]> {
    const [teamData, categoriesData] = await Promise.all([
        getTeams({ sort: 'position', limit: 0 }),
        getCategoriesBySlug({ sort: '-weight', limit: 0, parent: 'Team' }),
    ])
    const team = teamData as CMSList<Team>
    const categories = categoriesData as Category[]
    return categories.map((cat) => {
        return {
            category: cat,
            members: getTeamsSorted(team.data, cat)
                .filter((member) => member.categories.main === cat._id)
                .map((member: Team) => {
                    return {
                        name: member.name,
                        role: member.title,
                        imgSrc: member.imageUrl,
                        socialLinks: member.networks,
                        desc: member.bio,
                        id: member._id,
                    }
                }),
        } as GetTeamResult
    })
}

const getAssociations = async (associations: Associations) => {
    for (const propName in associations) {
        const prop = associations[propName]
        if (prop === undefined || prop.length === 0) {
            delete associations[propName]
        }
    }

    const assocValues = Object.entries(associations)
    const params: string[] = []
    assocValues.forEach((element) => {
        const [key, val] = element
        if (val) {
            val.forEach((value: string) => {
                params.push(`${key}=${value}`)
            })
        }
    })
    const queryString = params.join('&')
    return await getFromCmsWithQueryString(`api/associations?${queryString}`)
}

const postNewsletter = async (data: Newsletter) => {
    const response = await fetch(`${baseUrl}api/newsletter`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            site: site ?? '',
        },
    })

    const content = await response.json()
    return content
}

const getNews = async (args: GetDefaultParams) => {
    const newsData = await getFromCms(`papi/news`, args)
    const news = newsData as CMSList<News>
    news.data = news.data.map((p: News) => ({
        webpThumbnailUrl: getCMSImage(p.thumbnailUrl),
        ...p,
    }))
    return news
}

export {
    getPosts,
    getCategoriesBySlug,
    getBlogDetail,
    getCareers,
    getLogos,
    postNewsletter,
    getPostPage,
    getLegalDocs,
    getPostsSitemap,
    getTeams,
    getTeamPage,
    getCaseStudies,
    getCaseStudyPage,
    getBanner,
    getBannerPage,
    getBlog,
    getCategoryGroups,
    getNews,
}
