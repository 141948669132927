import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { Nav, Navbar, NavDropdown, Container, Offcanvas } from 'react-bootstrap'
import { myFonts } from '@configs/local-fonts'

import Icon from '@atk/components/icon'

type SubDropdownItemsProps = {
    linkLabel: string
    url?: string
    isExternal?: boolean
}

type DropdownItemsProps = {
    linkLabel?: string
    url?: string
    isExternal?: boolean
    hasSubDropdown?: boolean
    subDropdownLabel?: string
    subDropdownLink?: SubDropdownItemsProps[]
    isSubDropdownDisplayed?: boolean
}

type NavItemsProps = {
    linkLabel?: string
    url?: string
    isExternal?: boolean
    dropdownLinkLabel?: string
    dropdownLink?: DropdownItemsProps[]
    isDisplayed?: boolean
}

export type NavigationDefaultProps = {
    logoSrc: string
    items: NavItemsProps[]
}

export default function NavigationDefault({ items, logoSrc }: NavigationDefaultProps) {
    const [list, setList] = useState(items)
    const [stylesChange, setStyleChange] = useState(false)

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setStyleChange(true)
        } else {
            setStyleChange(false)
        }
    }
    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', changeNavbarColor)
    }
    const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 768 : false

    const handleToggle = (index: number) => {
        setList((currentList) =>
            currentList.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        isDisplayed: !item.isDisplayed,
                    }
                } else {
                    return {
                        ...item,
                        isDisplayed: isMobile ? false : item.isDisplayed,
                    }
                }
            })
        )
    }

    const handleDropdownHover = (parentIndex: number, submenuIndex: number) => {
        setList((currentList) =>
            currentList.map((item, parentIdx) => {
                if (parentIdx === parentIndex && item.dropdownLink) {
                    return {
                        ...item,
                        dropdownLink: item.dropdownLink.map((submenuItem, submenuIdx) => {
                            if (submenuIdx === submenuIndex) {
                                const isSubDropdownDisplayed = isMobile ? !submenuItem.isSubDropdownDisplayed : true
                                return { ...submenuItem, isSubDropdownDisplayed }
                            }
                            return isMobile ? submenuItem : { ...submenuItem, isSubDropdownDisplayed: false }
                        }),
                    }
                }
                return item
            })
        )
    }

    const hideDropdown = () => {
        const newItemList = list.map((item) => {
            const updatedDropdownLinks = item.dropdownLink?.map((dropdownItem) => {
                return {
                    ...dropdownItem,
                    isSubDropdownDisplayed: false,
                }
            })
            return { ...item, isDisplayed: false, dropdownLink: updatedDropdownLinks }
        })
        setList(newItemList)
    }

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://js.hscta.net/cta/current.js'
        script.async = true
        script.charset = 'utf-8'

        const loadScript2 = () => {
            const script2 = document.createElement('script')
            script2.type = 'text/javascript'
            script2.async = true
            script2.innerHTML = `hbspt.cta.load(6073987, '602a67f0-4f80-4c63-ae35-8550473738b0', {"useNewLoader":"true","region":"na1"});`

            document.body.appendChild(script2)
        }

        script.onload = loadScript2

        document.body.appendChild(script)

        return () => {
            script.onload = null
        }
    }, [])

    return (
        <Navbar bg="dark" expand="lg" fixed="top" className={stylesChange ? 'navbarOnScroll' : ''}>
            <Container>
                <Link className="navbar-brand" href="/" aria-label="Casper Labs">
                    <Icon source={logoSrc} />
                </Link>
                <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" onClick={handleShow} />
                <Navbar.Offcanvas
                    show={show}
                    onHide={handleClose}
                    id="offcanvasNavbar-expand-lg"
                    aria-labelledby="offcanvasNavbar-expand-lg"
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarHeaderTitle-expand-lg">
                            <Link className="navbar-brand" href="/" aria-label="Casper Labs">
                                <Icon source={logoSrc} />
                            </Link>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className={`${myFonts.className} justify-content-end flex-grow-1 pe-3`}>
                            {list?.map((item: NavItemsProps, parentIndex: number) => (
                                <React.Fragment key={`menu-item-${parentIndex}`}>
                                    {item.linkLabel && !item.isExternal && (
                                        <Link
                                            className="nav-link"
                                            key={parentIndex}
                                            href={item.url !== undefined ? item.url : ''}
                                            onClick={() => handleClose()}
                                        >
                                            {item.linkLabel}
                                        </Link>
                                    )}
                                    {item.linkLabel && item.isExternal && (
                                        <a
                                            className="nav-link"
                                            key={parentIndex}
                                            target="_blank"
                                            href={item.url !== undefined ? item.url : ''}
                                            rel="noreferrer"
                                            onClick={() => handleClose()}
                                        >
                                            {item.linkLabel}
                                        </a>
                                    )}
                                    {item.dropdownLink && (
                                        <NavDropdown
                                            title={item.dropdownLinkLabel}
                                            id={`dropdown-${parentIndex}`}
                                            show={item.isDisplayed}
                                            onClick={() => handleToggle(parentIndex)}
                                            onMouseEnter={() => handleToggle(parentIndex)}
                                            onMouseLeave={hideDropdown}
                                        >
                                            {item.dropdownLink.map((dropdownItem, index) => {
                                                const key = `dropdown-item-${parentIndex}-${index}-${dropdownItem.isSubDropdownDisplayed}`

                                                const dropdownEvents = isMobile
                                                    ? {
                                                          onClick: () => handleDropdownHover(parentIndex, index),
                                                      }
                                                    : {
                                                          onMouseEnter: () => handleDropdownHover(parentIndex, index),
                                                          onMouseLeave: () => handleDropdownHover(parentIndex, index),
                                                      }

                                                return dropdownItem.hasSubDropdown ? (
                                                    <NavDropdown
                                                        title={dropdownItem.subDropdownLabel}
                                                        id={`subDropdown-${parentIndex}-${index}`}
                                                        key={key}
                                                        show={dropdownItem.isSubDropdownDisplayed}
                                                        className="sub-dropdown dropdown-item"
                                                        {...dropdownEvents}
                                                    >
                                                        {dropdownItem.subDropdownLink?.map((subItem, subIndex) => (
                                                            <Link
                                                                key={`sub-item-${parentIndex}-${index}-${subIndex}`}
                                                                href={subItem.url || '#'}
                                                                className="dropdown-item"
                                                                onClick={() => handleClose()}
                                                            >
                                                                {subItem.linkLabel}
                                                            </Link>
                                                        ))}
                                                    </NavDropdown>
                                                ) : (
                                                    <Link
                                                        key={key}
                                                        href={dropdownItem.url || '#'}
                                                        className="dropdown-item"
                                                        onClick={() => handleClose()}
                                                    >
                                                        {dropdownItem.linkLabel}
                                                    </Link>
                                                )
                                            })}
                                        </NavDropdown>
                                    )}
                                </React.Fragment>
                            ))}
                            <span className="hs-contact-btn" id="hs-cta-wrapper-602a67f0-4f80-4c63-ae35-8550473738b0">
                                <span id="hs-cta-602a67f0-4f80-4c63-ae35-8550473738b0">
                                    <a href="https://cta-redirect.hubspot.com/cta/redirect/6073987/602a67f0-4f80-4c63-ae35-8550473738b0">
                                        Contact Us
                                    </a>
                                </span>
                            </span>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}
