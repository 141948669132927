const getCMSImage = (image: string) => {
    const lastDotPosition = image.lastIndexOf('.')
    if (lastDotPosition === -1) return image
    else return image.substr(0, lastDotPosition) + '.webp'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const setHorizontalScrollToElement = (el: any) => {
    let isDown = false
    let startX = 0
    let scrollLeft = 0
    el.addEventListener('mousedown', (e: MouseEvent) => {
        el.classList.add('carouselDragging')
        isDown = true
        startX = e.pageX - el.offsetLeft
        scrollLeft = el.scrollLeft
    })
    el.addEventListener('mouseleave', () => {
        isDown = false
        el.classList.remove('carouselDragging')
    })
    el.addEventListener('mouseup', () => {
        isDown = false
        el.classList.remove('carouselDragging')
    })
    el.addEventListener('mousemove', (e: MouseEvent) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - el.offsetLeft
        const walk = (x - startX) * 3
        el.scrollLeft = scrollLeft - walk
    })
}

export { getCMSImage, setHorizontalScrollToElement }
