import { ThemeProvider } from 'react-bootstrap'
import SSRProvider from 'react-bootstrap/SSRProvider'

type AppBootstrapProviderProps = {
    children: React.ReactNode
}

function AppBootstrapProvider({ children }: AppBootstrapProviderProps) {
    return (
        <SSRProvider>
            <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} minBreakpoint="xxs">
                {children}
            </ThemeProvider>
        </SSRProvider>
    )
}

export default AppBootstrapProvider
