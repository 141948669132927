import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import TwitchIcon from '@assets/icons/twitch.svg'
import LinkedinIcon from '@assets/icons/linkedin.svg'
import TwitterIcon from '@assets/icons/twitter.svg'
import YoutubeIcon from '@assets/icons/youtube.svg'
import Link from 'next/link'
import { getLegalDocs } from 'src/@shared/service/cms-api'
import { useQuery } from 'react-query'
import { CMSList } from 'src/@shared/types/cms/cms-list'

import Icon from '@atk/components/icon'

import styles from './Footer.module.scss'
import { LegalDoc } from '../../../types/cms/legalDoc'

type CategoryItemsProps = {
    linkLabel?: string
    linkUrl?: string
    isExternal?: boolean
}

type FooterItemsProps = {
    categoryTitle?: string
    categoryItems?: CategoryItemsProps[]
    content?: string
    url?: string
    isExternal?: boolean
    colNumber: number
    colMDNumber?: number
}

const initialArgs = { sort: 'position', limit: 0, page: 1 }

export type FooterProps = {
    logoSrc: string
    items: FooterItemsProps[]
}

function Footer({ items, logoSrc }: FooterProps) {
    const { data } = useQuery(['legaldocs'], () => getLegalDocs(initialArgs))
    const legalDocs = (data as CMSList<LegalDoc>)?.data
    const componentClass = `footer ${styles.footer}}`

    const getLink = (type: string) => {
        switch (type) {
            case 'terms':
                return 'Terms of Service'
            case 'privacy':
                return 'Privacy Policy'
            case 'trademark':
                return 'Trademark Policy'
            default:
                return ''
        }
    }

    return (
        <footer className={componentClass}>
            <section className="pt-lg-7 pb-5">
                <Container>
                    <Icon customClass="footer-logo" source={logoSrc} />
                    <Row className="my-5 gx-lg-5">
                        {items.map((item: FooterItemsProps, index: number) => (
                            <Col key={index} className="mb-5 mb-lg-0" md={item.colMDNumber} lg={item.colNumber}>
                                <>
                                    {item.content && <p>{item.content}</p>}
                                    {item.categoryItems && (
                                        <>
                                            <p className="category">{item.categoryTitle}</p>
                                            <div>
                                                <ul>
                                                    {item.categoryItems.map(
                                                        (cat: CategoryItemsProps, index: number) => (
                                                            <li key={index}>
                                                                <a
                                                                    className="text-decoration-none"
                                                                    href={cat.linkUrl}
                                                                    target={cat.isExternal ? '_blank' : '_self'}
                                                                    rel="noreferrer"
                                                                >
                                                                    {cat.linkLabel}
                                                                </a>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </>
                            </Col>
                        ))}

                        <Row className="social-media w-auto mb-4">
                            <Col>
                                <a
                                    className="text-decoration-none"
                                    href="https://www.linkedin.com/company/casperlabs/"
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="Linkedin Link"
                                >
                                    <Icon source={LinkedinIcon.src} />
                                </a>
                            </Col>
                            <Col>
                                <a
                                    className="text-decoration-none"
                                    href="https://twitter.com/Casper_Network"
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="Twitter Link"
                                >
                                    <Icon source={TwitterIcon.src} />
                                </a>
                            </Col>
                            <Col>
                                <a
                                    className="text-decoration-none"
                                    href="https://www.youtube.com/@casper_labs"
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="Youtube Link"
                                >
                                    <Icon source={YoutubeIcon.src} />
                                </a>
                            </Col>
                            <Col>
                                <a
                                    className="text-decoration-none"
                                    href="https://discord.com/invite/Q38s3Vh"
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="Discord Link"
                                >
                                    <Icon source={TwitchIcon.src} />
                                </a>
                            </Col>
                        </Row>
                    </Row>

                    <Row className="copyright justify-content-between">
                        <hr className="mb-4" />
                        <Row>
                            <Col md="4">
                                <p className={styles.desc}>2023 Casper Labs. All Rights Reserved.</p>
                            </Col>
                            <Col md="8" className="d-flex justify-content-end">
                                {legalDocs &&
                                    legalDocs.map((p: LegalDoc) => (
                                        <Link key={p._id} href={`/legal/${p.meta.slug}`}>
                                            {getLink(p.type)}
                                        </Link>
                                    ))}
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </section>
        </footer>
    )
}

export default Footer
